<template>
  <div class="card" no-body>
    <b-card-header header-tag="header" class="p-0" role="tab">
      <b-button
        block
        v-b-toggle="`collapsed-item-${index}`"
        variant="primary"
        class="btn-header-link"
      >
      {{caption}}
      </b-button>
    </b-card-header>

    <b-collapse :id="`collapsed-item-${index}`">
      <b-card>
        <div class="footer">
          <ul class="footer_list">
            <li v-for="(item, j) in itemList" :key="j">
              <a class="footer-item" :href="(path === '') ? item[0] :`/hotels-page/?${path}=${item[0]}`"> {{item[1]}}</a>
            </li>
          </ul>
        </div>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  BButton,
  BCollapse,
  VBToggle,
  BCard,
  BCardHeader,
} from 'bootstrap-vue';

export default {
  name: 'footer-column-mobile',
  components: {
    BButton,
    BCollapse,
    BCard,
    BCardHeader,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    itemList: {
      type: Array,
      default: () => [],
    },
    path: {
      type: String,
      default: () => '',
    },
    caption: {
      type: String,
      default: null,
    },
    index: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
    }),
  },
  data() {
    return {
    };
  },
  created() {
  },
};
</script>

<style scoped>
header button.btn {
  text-align: inherit;
}
.card .card-header .btn-header-link.collapsed:after {
  content: '\f107';
}
.card .card-header .btn-header-link:after {
  content: '\f106';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  float: left;
}
.card {
  margin-bottom: 5px;
}
.card-body, .card .card-header .btn-header-link{
  background: #0161ab;
}
a.footer_item{
  color: #e9e9e9 !important;
  text-decoration: none !important;
  font-weight: 300;
  transition: 0.5s;
}
a {
  color: #007bff;
  text-decoration: none !important;
  background-color: transparent;
}
</style>
